import useStore from "../../store"
import { useState } from "react"
import BankAccountRow from "./BankAccountRow"
import BankAccountForm from "./BankAccountForm"
import { Link, useNavigate } from "react-router-dom"
import { FaPlusCircle } from "react-icons/fa"
import { IoMdClose } from "react-icons/io"
import AlertMessage from "../common/AlertMessage"
import { useTranslation } from 'react-i18next'

export default function BankAccountTable () {
  const setErrMessage = useStore((state) => state.setErrMessage)
  const bankAccounts = useStore((state) => state.bankAccounts)
  const [showAccountForm, setShowAccountForm] = useState(false)
  const authNeeded = bankAccounts?.some((acct) => acct.attributes["needs-ach-auth"])
  const { t } = useTranslation()
  const navigate = useNavigate()

  return(
    <div className="w-full lg:w-1/2 text-left p-2 bg-grey-900 rounded mt-2">
      <div className="sticky top-0 left-0 bg-grey-900 pb-2">
        <h3 className="col-span-2 font-header font-semibold text-md text-green">{t('Bank Accounts')}</h3>
      </div>
      {bankAccounts?.map((account) => {
        return(
          <BankAccountRow account={account} key={account.id} />
        )
      })}
      {authNeeded &&
        <div>
          <AlertMessage
            message={<div onClick={() => navigate("/ach_auth")}>{t('Click to authorize bank accounts')}</div>} 
            style="text-yellow justify-center border-none underline"
          />
        </div>
      }
      <div
        className={`relative overflow-hidden transition-all duration-500 ${showAccountForm ? 'h-[33rem]' : 'h-8'}`}
        data-testid='form-container'
      >
        <div
          className="flex items-center my-2 hover:cursor-pointer"
          onClick={() => setShowAccountForm(!showAccountForm)}
        >
          {showAccountForm ?
            <IoMdClose className="my-1 mx-1 mr-1.5" data-testid="close-form" /> :
            <div className="flex items-center">
              <FaPlusCircle className="mx-1 mr-1.5" />
              {t('Add Bank Account')}
            </div>
            }
        </div>
        <div className="absolute top-9 left-6">
          {showAccountForm && <BankAccountForm setShowAccountForm={setShowAccountForm} />}
        </div>
      </div>
    </div>
  )
}