export const liveSupportEnabled = import.meta.env.VITE_LIVE_SUPPORT_ENABLED  === 'true'
export const pushNotificationsEnabled = import.meta.env.VITE_PUSH_NOTIFICATIONS_ENABLED === 'true'
export const napBankPayoffEnabled = import.meta.env.VITE_NAP_BANK_PAYOFF_ENABLED  === 'true'
export const couponsEnabled = import.meta.env.VITE_COUPONS_ENABLED  === 'true'
export const v2TransferEnabled = import.meta.env.VITE_V2_TRANSFER_ENABLED  === 'true'
export const fieldAuditsTableEnabled = import.meta.env.VITE_FIELD_AUDITS_TABLE_ENABLED  === 'true'
export const amPortalRemindersEnabled = import.meta.env.VITE_AM_PORTAL_REMINDERS_ENABLED === 'true'
export const previousInventoryReportEnabled = import.meta.env.VITE_PREVIOUS_INVENTORY_REPORT_ENABLED === 'true'
export const hubspotLiveChatEnabled = (user) => import.meta.env.VITE_WHITELISTED_CHAT_BRANCH_IDS.split(',').includes(user?.branchId.toString()) && import.meta.env.VITE_HUBSPOT_LIVE_CHAT_ENABLED === 'true'
export const markUnitSoldEnabled = import.meta.env.VITE_MARK_UNIT_SOLD_ENABLED === 'true'
