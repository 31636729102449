import { apiUrl, apiUrlV2 } from "../constants/apiUrl"
import sortUnitsByNapIssues from "../utilities/sortUnitsByNapIssues"

const initialState = {
  units: [],
  unit: undefined,
  comingDueUnits: [],
  soldUnits: [],
  amountDueOn: {},
  isFetchingAmountDue: false,
  unitsFetching: false,
  unitFetching: false,
  dashboardUnitId: null,
  unitTitle: null,
  downloadingPreviousUnits: false,
  previousUnits: [],
  markingUnitSold: false
}


const createUnitsSlice = (set, get) => ({
  ...initialState,
  fetchUnits: async () => {
    set((state) => ({ unitsFetching: true }))
    try {
      const response = await fetch(
        `${apiUrlV2}/units?filter[scope]=current&sort[for_dealers]=true&per_page=1000`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()

      set((state) => ({ units: data.data }))
    } catch (err) {
      get().setErrMessage('Unit data was not able to be fetched. Please refresh to try again')
    } finally {
      set(() => ({ unitsFetching: false }))
    }
  },
  fetchPreviousUnits: async (startOn, endOn, reportType) => {
    set((state) => ({ downloadingPreviousUnits: true }))
    try {
      const response = await fetch(
        `${apiUrl}/units/previous-inventory-report?report_type=${reportType}&start_on=${startOn}&end_on=${endOn}`,
        {
          headers: get().requestHeaders,
          method: "POST"
        }
      )

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      if (reportType === 'PDF') {
        link.download = `PreviousInventoryReport${startOn}-${endOn}.pdf`
      } else {
        link.download = `PreviousInventoryReport${startOn}-${endOn}.xls`
      }
      link.click()
    } catch (err) {
      get().setErrMessage('Error downloading PDF')
    } finally {
      set(() => ({ downloadingPreviousUnits: false }))
    }
  },
  fetchUnit: async (unitId) => {
    set(() => ({ unitFetching:true }))
    try {
      const response = await fetch(
        `${apiUrlV2}/units/${unitId}`,
        { headers: get().requestHeaders }
      )
      const data = await response.json()

      if (data.errors) {
        set((state) => ({ unit: data }))
      } else {
        set((state) => ({ unit: data.data }))
      }
    } catch (err) {
      get().setErrMessage('There was an error fetching this units data. Please try again')
    } finally {
      set(() => ({ unitFetching: false }))
    }
  },
  markUnitSold: async (unit, today) => {
    set(() => ({ markingUnitSold: true }))
    try {
      const response = await fetch(
        `${apiUrl}/units/${unit.id}/sold`,
        {
          headers: get().requestHeaders,
          method: "PUT",
          body: JSON.stringify({ unit: {sold_on: today} })
        }
      )
      const data = await response.json()
      set((state) => ({ unit: data.data }))
      get().fetchUnits()
    } catch (err) {
      get().setErrMessage('There was an error marking unit sold. Please try again')
    } finally {
      set(() => ({ markingUnitSold: false }))
    }
  },
  fetchAmountDueOn: (unitId, date) => {
    set((state) => ({ isFetchingAmountDue: true }))
    fetch(
      `${apiUrl}/units/${unitId}/amounts_due_on?amounts_due_on_date=${date}`,
      { headers: get().requestHeaders }
    )
      .then(response => response.json())
      .then(data => set((state) => ({ amountDueOn: data, isFetchingAmountDue: false  })))
  },
  cancelNap: (unitId) => fetch(
    `${apiUrlV2}/units/${unitId}/cancel_nap`, {
      method: 'PUT',
      headers: get().requestHeaders
    }
  ),
  updateUnit: (unitId, body) => fetch(
    `${apiUrlV2}/units/${unitId}`, {
      method: 'PUT',
      headers: { 'Authorization': get().requestHeaders['Authorization']},
      body: body
    }
  ),
  pendingAndHistoricNaps: () => {
    return(
      get().units?.filter((unit) => unit.attributes['created-by-type'] === 'DealerUser')?.reverse()
    )
  },
  setUnit: (unit) => set((state) => ({ unit: unit })),
  setDashboardUnitId: (id) => set(() => ({ dashboardUnitId: id })),
  fetchUnitTitle: (unitId) => fetch(
    `${apiUrl}/units/${unitId}/title`, {
      method: 'POST',
      headers: get().requestHeaders
    }
  ),
  resetUnitsSlice: () => set(initialState)
})

export default createUnitsSlice