import { useEffect } from 'react'
import useStore from '../../store'
import useElementObserver from '../../hooks/useElementObserver'
import { DateTime } from 'luxon'
import Rollbar from 'rollbar'
import rollbarConfig from '../../configs/rollbarConfig'

const HubspotChat = ({ hubId, user }) => {
  const visitorToken = useStore((state) => state.visitorToken)
  const visitorTokenRetrievedAt = useStore((state) => state.visitorTokenRetrievedAt)
  const fetchVisitorToken = useStore((state) => state.fetchVisitorToken)
  const rollbar = new Rollbar(rollbarConfig(user))

  // If token is older than 8 hours, it fetches a new one
  useEffect(() => {
    if (!user) return

    const isTokenExpired = visitorTokenRetrievedAt
      ? DateTime.fromISO(visitorTokenRetrievedAt).plus({ hours: 8 }) < DateTime.now()
      : true

    if (isTokenExpired || !visitorToken) {
      fetchVisitorToken(user)
    }
  }, [user, visitorToken, visitorTokenRetrievedAt])

  useEffect(() => {
    if (!user || !visitorToken) return

    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: user.email,
      identificationToken: visitorToken,
    }

    if (window.HubSpotConversations) {
      window.HubSpotConversations.clear({ resetWidget:true })
      window.HubSpotConversations.widget.load()
    }

    return () => {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.clear({ resetWidget:true })
        window.HubSpotConversations.widget.remove()
      } else {
        rollbar.warning('HubSpot widget was not properly initialized during cleanup')
      }
    }
  }, [hubId, user, visitorToken])

  useElementObserver('hubspot-messages-iframe-container', (hubspotContainer) => {
    if (hubspotContainer) {
      hubspotContainer.style.colorScheme = 'light'
    } else {
      rollbar.warning('HubSpot iframe container was not found')
    }
  })

  return null
}

export default HubspotChat