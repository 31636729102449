import { useEffect } from "react"

export default function useElementObserver(elementId, callback) {
  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const element = document.querySelector(`#${elementId}`)
          if (element) {
            callback(element)
            observer.disconnect
            break
          }
        }
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return() => observer.disconnect()
  }, [elementId, callback])
}