import unitStatus from './unitStatus'
import formatter from '../constants/currencyFormatter'

const unitFilter = (units, searchTerm) => {
  const searchTerms = searchTerm.toLowerCase().split(/\s+/)

  const matchesFilter = (unit, parameter, term) => {
    return unit.attributes[parameter]?.toString().toLowerCase().includes(term)
  }

  return units.filter((unit) => {
    return searchTerms.every((term) => {
      return (
        matchesFilter(unit, 'make', term) ||
        matchesFilter(unit, 'model', term) ||
        matchesFilter(unit, 'vin', term) ||
        matchesFilter(unit, 'color', term) ||
        matchesFilter(unit, 'year', term) ||
        unitStatus(unit).text.toLowerCase().includes(term) ||
        formatter.format(unit.attributes.payoff).includes(term) ||
        formatter.format(unit.attributes.curtailment).includes(term)
      )
    })
  })
}

export default unitFilter
