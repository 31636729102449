import { useTranslation } from "react-i18next"
import SearchBar from "../../common/SearchBar"
import { IoFilterSharp } from "react-icons/io5"
import tailwind from "../../../../tailwind.config"
import DatePicker from "react-datepicker"

export default function CompletedTransfersHeader({
  searchTerm,
  setSearchTerm,
  transferParams,
  setTransferParams,
  showFilters,
  setShowFilters
}) {
  const { t } = useTranslation()
  const grey100 = tailwind.theme.colors["grey-100"]

  return(
    <div className="sticky top-0 left-0 bg-grey-900 rounded w-full">
      <div className="flex-col w-full pt-2 pb-3 px-2">
        <div className="flex w-full items-center">
          <h3 className="font-header font-semibold text-md text-green mr-1">
            {t('Past Transactions')}
          </h3>
          <div className="grow">
            <SearchBar onChange={(e) => setSearchTerm(e)} value={searchTerm}/>
          </div>
          <div
            className="ml-2 mr-1 hover:cursor-pointer"
            onClick={() => setShowFilters(!showFilters)}
            data-testid="open-filter"
          >
            <IoFilterSharp />
          </div>
        </div>
        {showFilters && (
          <div className="flex flex-col justify-around my-1 transition-all duration-300">
            <div className="flex flex-col w-full mb-2">
              <span className="text-sm text-grey-300 mb-2">{t("Start Date")}</span>
              <DatePicker
                selected={transferParams["filter[start_date]"]}
                onChange={(date) => setTransferParams({ ...transferParams, "filter[start_date]": date })}
                className="p-0.5 w-full bg-grey-900 border-1 border-grey focus:border-grey-300 focus:ring-0 rounded"
                placeholderText="Start Date"
              />
            </div>
            <div className="flex flex-col w-full mb-2">
              <span className="text-sm text-grey-300 mb-2">{t("End Date")}</span>
              <DatePicker
                selected={transferParams["filter[end_date]"]}
                onChange={(date) => setTransferParams({ ...transferParams, "filter[end_date]": date })}
                className="p-0.5 w-full bg-grey-900 border-1 border-grey focus:border-grey-300 focus:ring-0 rounded"
                placeholderText="End Date"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}