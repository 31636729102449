import useStore from "../../../store"
import { useTranslation } from "react-i18next"
import InfoItem from "../../common/InfoItem"
import formatter from "../../../constants/currencyFormatter"
import UnitTitleDownload from "./UnitTitleDownload"
import UnitTitleUnavailable from "./UnitTitleUnavailable"
import { DateTime } from "luxon"
import { useEffect } from "react"
import Spinner from "../../common/Spinner"
import MarkSold from "./MarkSold"
import { markUnitSoldEnabled } from "../../../utilities/featureFlags"

export default function UnitInfo() {
  const unit = useStore((state) => state.unit)
  const user = useStore((state) => state.user)
  const { t } = useTranslation()
  const amountDueOn = useStore((state) => state.amountDueOn)
  const fetchAmountDueOn = useStore((state) => state.fetchAmountDueOn)
  const dueOnDate = DateTime.fromISO(unit?.attributes['due-on'])
  const isPastDue = dueOnDate < DateTime.now()
  const isFetchingTransfer = useStore((store) => store.isFetchingTransfer)

  useEffect(() => {
    fetchAmountDueOn(unit.id, new Date().toLocaleDateString('en-ZA'))
  }, [])

  const handlePaymentAmount = () => {
    if (unit?.attributes["ach-initiated"]) {
      return "Transfer Pending"
    }
    if (amountDueOn?.id?.toString() === unit.id) {
      return formatter.format(amountDueOn.payoff)
    }
    return <Spinner height="20"/>
  }

  return(
    <div className="grid grid-cols-2 w-full text-left p-2 bg-grey-900 rounded">
      <h3 className="col-span-2 font-header font-semibold text-md text-green">{t('Unit Information')}</h3>
      <InfoItem
        title={t("Original Principal")}
        data={formatter.format(unit?.attributes['original-principal'])}
      />
      <InfoItem
        title={t("Payoff Amount")}
        data={handlePaymentAmount()}
      />
      <InfoItem
        title={t('Due On')}
        data={dueOnDate.toLocaleString(DateTime.DATE_MED)}
        alert={isPastDue}
      />
      <InfoItem
        title={'VIN'}
        data={unit.attributes.vin}
      />
      <InfoItem
        title={t('Color')}
        data={unit.attributes.color}
      />
      <InfoItem
        title={t('Stock Number')}
        data={unit.attributes['stock-number']}
      />
      <InfoItem
        title={t('Days')}
        data={unit.attributes['days-floored']}
      />
      <InfoItem
        title={t('Refloors Remaining')}
        data={unit.attributes['maximum-refloors'] - (unit.attributes['current-floor'] - 1)}
      />

      {user.type === 'AccountManager' &&
        <>
          <InfoItem
            title={'Title'}
            data={unit.attributes['title-status']}
          />
          <InfoItem
            title={'Days Since Last Touch'}
            data={unit.attributes['days-since-last-touch']}
            alert={unit.attributes['days-since-last-touch'] >= 40 || unit.attributes['days-since-last-touch'] === 'Never Touched'}
          />
        </>
      }
      <div className="col-span-2">
        {unit.attributes.sold ?
          <InfoItem
            title={t('Sold On')}
            data={unit?.attributes['sold-on']}
          />
          :
          markUnitSoldEnabled && <MarkSold/>
        }
        {unit.attributes['title-file-url'] ?
          <UnitTitleDownload unit={unit} />
        :
          <UnitTitleUnavailable />
        }
      </div>
    </div>
  )
}