import { FaSearch } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import { FaUndoAlt } from "react-icons/fa"
import useStore from "../../store"

export default function SearchBar({ onChange, value }) {
  const { t } = useTranslation()
  const setDealerUnitsSearchBarValue = useStore((state) => state.setDealerUnitsSearchBarValue)
  const dealerUnitsSearchBarValue = useStore((state) => state.dealerUnitsSearchBarValue)

  const handleReset = () => {
    setDealerUnitsSearchBarValue('')
  }

  return(
    <label
      className={`flex items-center justify-start w-full text-grey
      border border-grey rounded-full hover:cursor-pointer
      focus-within:text-grey-100 focus-within:border-grey-100`}
    >
      {
        dealerUnitsSearchBarValue ?
        <FaUndoAlt className="mx-1" onClick={handleReset}/>
        :
        <FaSearch className="mx-1" />
      }
      <input
        type="text"
        aria-label="search-units"
        autoComplete="off"
        className="w-full h-6 bg-grey-900 rounded-full border-none px-0.5
        focus:outline-none focus:border-grey-100 focus:ring-0
        placeholder:text-grey"
        onChange={(e) => onChange(e.target.value)}
        placeholder={t("Search")}
        value={value}
      />
    </label>
  )
}