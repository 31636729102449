import useStore from "../../../store"
import UnitRow from "./UnitRow"
import unitFilter from "../../../utilities/unitFilter"
import AlertMessage from "../../common/AlertMessage"
import Pagination from "../../common/Pagination"

export default function UnitTable({searchTerm, setSearchTerm}) {
  const units = useStore((state) => state.units)
  const user = useStore((state) => state.user)
  const setDealerUnitsPage = useStore((state) => state.setDealerUnitsPage)
  const { currentPage, lastPage, dealerUnitsResultsCount, totalPages } = useStore((state) => state.dealerUnitsPaginationData)
  const filteredUnits = unitFilter(units, searchTerm)
  const searchTerms = searchTerm.toLowerCase().split(/\s+/)

  const handlePageClick = (event) => {
    setDealerUnitsPage(event.selected + 1)
  }

  const matchesFilter = (unit, parameter, term) => {
    return unit.attributes[parameter]?.toString().toLowerCase().includes(term)
  }

  const matchedAttributes = (unit) => {
    if (searchTerm.length < 3) return null

    if (searchTerms.some((term) => matchesFilter(unit, 'vin', term))) {
      return 'VIN'
    }
    if (searchTerms.some((term) => matchesFilter(unit, 'color', term))) {
      return 'Color'
    }

    return null
  }

  return (
    <div className="w-full bg-grey-900 rounded">
      <div className="flex flex-col items-center w-full lg:h-full p-2 bg-grey-900 rounded-b">
        {filteredUnits.length == 0 ?
          <div><div><AlertMessage message="No results found" /></div></div> :
          filteredUnits.map((unit) => <UnitRow unit={unit} key={unit.id} matchedAttribute={matchedAttributes(unit)} filter={searchTerm}/>)
        }
      </div>
      {user.type === 'AccountManager' &&
        <div className="flex justify-center">
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            dataTotal={dealerUnitsResultsCount}
            handlePageClick={handlePageClick}
            totalPages={totalPages}
          />
        </div>
      }
    </div>
  )
}
