import { apiUrl, apiUrlV2 } from "../constants/apiUrl"

const initialState = {

}

const createUnitFilesSlice = (set, get) => ({
  ...initialState,
  createUnitFile: (body) => fetch(
    `${apiUrl}/unit-files`, {
      method: 'POST',
      headers: { 'Authorization': get().requestHeaders['Authorization']},
      body: body
    }
  ),
  resetUnitFilesSlice: () => set(initialState)
})

export default createUnitFilesSlice