import useStore from "../../../store"
import { useState, useEffect } from "react"
import Spinner from "../../common/Spinner"
import formatter from "../../../constants/currencyFormatter"
import { useTranslation } from "react-i18next"
import BankAccountSelect from "./BankAccountSelect"
import PaymentNumberInput from "./PaymentNumberInput"
import { DateTime } from "luxon"
import { v2TransferEnabled } from "../../../utilities/featureFlags"
import { Input } from "@chakra-ui/react"
import tailwind from "../../../../tailwind.config"
import DatePicker from "react-datepicker"

export default function PaymentForm() {
  const { t } = useTranslation()
  const unit = useStore((state) => state.unit)
  const user = useStore((state) => state.user)
  const amountDueOn = useStore((state) => state.amountDueOn)
  const fetchAmountDueOn = useStore((state) => state.fetchAmountDueOn)
  const isFetchingAmountDue = useStore((state) => state.isFetchingAmountDue)
  const createPayment = useStore((state) => state.createPayment)
  const createPaymentStatus = useStore((state) => state.createPaymentStatus)
  const paymentType = useStore((state) => state.paymentType)
  const setPaymentMessage = useStore((state) => state.setPaymentMessage)
  const grey100 = tailwind.theme.colors["grey-100"]
  const unitId = unit.id
  const userId = user.dealerUserId
  const branchId = user.branchId
  const [transferDate, setTransferDate] = useState(new Date())
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [paymentAmount, setPaymentAmount] = useState(0)

  useEffect(() => {
    if (paymentType == 3) {
      setPaymentAmount(amountDueOn.payoff)
    } else if (paymentType == 1) {
      setPaymentAmount(amountDueOn.refloor)
    } else {
      setPaymentAmount(0)
    }
  }, [paymentType, amountDueOn])

  const handleDateChange = (date) => {
    setTransferDate(date.toLocaleDateString('en-ZA'))
    fetchAmountDueOn(unitId, date.toLocaleDateString('en-ZA'))
  }

  const handleSubmit = async () => {
    if (paymentAmount > 0) {
      await createPayment(paymentRequestBody())
      setPaymentMessage(null)
    } else {
      setPaymentMessage('Payment amount cannot be $0.00 or less')
    }
  }

  const disableWeekends = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  const paymentRequestBody = () => ({
    ach: {
      user_id: userId,
      branch_id: branchId,
      payment_type: paymentType,
      transfer_date: transferDate,
      amount: paymentAmount,
      account_id: selectedAccount.id,
      unit_id: unitId
    }
  })

  return(
    <div>
      <form className="w-3/4">
        <div className="flex flex-col mx-2 mb-1">
          <label className="text-sm text-grey-300 mb-1">{t('Payment Date')}</label>
          <DatePicker
            selected={transferDate}
            onChange={(date) => handleDateChange(date)}
            minDate={new Date()}
            filterDate={disableWeekends}
            className="px-1 w-full bg-grey-900 border-2 border-grey-700 focus:border-grey-300 focus:ring-0 rounded"
            maxDate={(v2TransferEnabled && unit?.attributes['maximum-transfer-schedule-date']) && DateTime.fromISO(unit?.attributes['maximum-transfer-schedule-date']).toLocaleString()}
          />

        </div>
        <div className="mx-2 mb-1">
          <p className="text-sm text-grey-300">{t('Payment Amount')}</p>
          {paymentType == 7 ?
            <PaymentNumberInput
              amount={paymentAmount}
              setAmount={setPaymentAmount}
              warning={parseFloat(amountDueOn?.payoff) < parseFloat(paymentAmount)  ? 'Principal buydown amount is greater than payoff amount' : null}
            /> :
            <p className="px-1 py-2 h-11 w-1/2 bg-grey-900 border-2 border-grey-900 rounded">
              {isFetchingAmountDue ? ' ' : formatter.format(paymentAmount)}
            </p>
          }
        </div>
        <div className="mx-2 mb-1">
          <BankAccountSelect
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        </div>
      </form>
      <div className="m-3">
        {createPaymentStatus == 'pending' || isFetchingAmountDue ?
          <Spinner color="green" /> :
          <button
            onClick={handleSubmit}
            className={`hover:cursor-pointer ${(selectedAccount == null || paymentAmount <= 0) && 'opacity-50'} font-bold border border-2 border-green py-1 px-3 text-sm rounded-full`}
            disabled={selectedAccount == null || paymentAmount <= 0}
          >
            {t('Submit')}
          </button>
        }
      </div>
    </div>
  )
}