import useStore from "../../../store"
import formatter from "../../../constants/currencyFormatter"
import formatAccountNumber from '../../../utilities/accountNumber'

export default function PaymentConfirm() {
  const payment = useStore((state) => state.payment)
  const cancelPayment = useStore((state) => state.cancelPayment)
  const processPayment = useStore((state) => state.processPayment)
  const setPaymentStatus = useStore((state) => state.setPaymentStatus)
  const setPaymentMessage = useStore((state) => state.setPaymentMessage)
  const setPayment = useStore((state) => state.setPayment)
  const fetchUnit = useStore((state) => state.fetchUnit)
  const unit = useStore((state) => state.unit)
  const fetchUnits = useStore((state) => state.fetchUnits)

  const handleCancel = async () => {
    const response = await cancelPayment(payment.id)
    const data = await response.json()
    setPaymentMessage(data.message)
    setPaymentStatus('idle')
  }

  const handleConfirm = async () => {
    setPaymentStatus('confirming')
    const response = await processPayment(payment.id)
    if (response.ok) {
      const data = await response.json()
      setPayment(data)
      setPaymentStatus('confirmed')
      fetchUnit(payment.attributes['unit-id'])
      fetchUnits()
    } else {
      setPayment([{ message: 'Unable to process payment at this time. Please try again.'}])
      setPaymentStatus('confirmed')
    }
  }

  return(
    <div>
      <div className="flex justify-evenly mb-4">
        <div className="text-sm rounded py-0.5 px-2 decoration-green underline underline-offset-8 decoration-4">
          {payment.attributes['payment-type']}
        </div>
      </div>
      <div className="flex flex-col mx-2 mb-1">
        <label className="text-sm text-grey-300">Payment Date</label>
        <div className="px-1">
          {new Date(payment.attributes['transfer-date']).toLocaleDateString('en-US', {timeZone: "UTC"})}
        </div>
      </div>
      <div className="mx-2 mb-1">
        <p className="text-sm text-grey-300">Payment Amount</p>
        <p className="px-1">{formatter.format(payment.attributes.amount)}</p>
      </div>
      <div className="mx-2 mb-1">
        <label className="text-sm text-grey-300">Bank Account</label>
        <p className="px-1">{payment.attributes['account-name-for-dealer']}</p>
        <p className="text-sm px-4">Routing: {formatAccountNumber(payment.attributes['safe-rt'])}</p>
        <p className="text-sm px-4">Account Number: {formatAccountNumber(payment.attributes['safe-act'])}</p>
      </div>
      <div className="text-grey-300 text-sm px-2 py-1">
        <h4>ACH Transfer Policy</h4>
        <p className="text-xs">All ACH transfers that are submitted after 3:00pm CST Monday - Thursday, and 1:00pm CST on Fridays, will be processed the following day.* Any additional fees and interest that accrue at the end of business today will be added to your confirmed ACH transfer.</p>
        <p className="text-xs">*ACH cutoff on 12/31/2024 will be at 1:00pm CST</p>
      </div>
      <div>
        <button 
          className="bg-green hover:cursor-pointer font-bold py-1 px-3 m-3 text-sm rounded"
          onClick={handleConfirm}
        >
          Confirm
        </button>
        <button 
          className="border border-green hover:cursor-pointer font-bold py-1 px-3 m-3 text-sm rounded"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}