export default function HighlightMatch({str, match, truncate = false}) {
  if (!match || !str) return <div className={`w-full ${truncate && "truncate text-ellipsis"}`}>{str}</div>

  const matches = match.split(/\s+/)
  const regex = new RegExp(`(${matches.join("|")})`, "gi")

  const parts = str.split(regex)

  return (
    <div className={`w-full ${truncate && "truncate text-ellipsis"}`}>
      {parts.map((part, index) =>
        matches.some((term) => term.toLowerCase() === part.toLowerCase()) ? (
          <span key={index} className="text-green">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </div>
  );
}