import { apiUrl, apiUrlV2 } from "../constants/apiUrl"
import { DateTime } from "luxon"

const initialState = {
  hubspotUser: null,
  visitorToken: null,
  hubspotChatRendered: false,
  visitorTokenRetrievedAt: null
}

const createHubspotSlice = (set, get) => ({
  ...initialState,
  fetchVisitorToken: async (user) => {
    const body = {
      "email": user.email,
      "firstName": user.dealer_first_name
    }

    try{
      const response = await fetch(`${apiUrlV2}/hubspot/create_hubspot_token`, {
        method: "POST",
        headers: get().requestHeaders,
        body: JSON.stringify(body)
      })

      const data = await response.json()
      set(() => ({
        visitorToken: data.token,
        visitorTokenRetrievedAt: DateTime.now().toISO()
      }))
      console.log("Visitor Token:", data.token)
    } catch (err) {
      console.error("Failed to generate visitor token:", err)
    } finally {

    }
  },
  setVisitorTokenRetrievedAt: (date) => set(() => ({ visitorTokenRetrievedAt: date })),
  resetHubspotSlice: () => set(initialState)
})

export default createHubspotSlice