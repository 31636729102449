import UploadUnitFile from "./UploadUnitFile"
import { useTranslation } from "react-i18next"

export default function UnitFiles() {
  const { t } = useTranslation()

  return(
    <div className="flex flex-col w-full text-left bg-grey-900 rounded mt-2">
      <h3 className="font-header font-semibold text-md text-green m-2">{t('Unit Files')}</h3>
      <UploadUnitFile/>
    </div>
  )
}