import useStore from "../../store"
import { FaCaretDown, FaCaretUp } from "react-icons/fa"

export default function TableSortingByHeader({ title, attribute, filterAttribute, filterDirection, setFilterAttribute, setFilterDirection, width, titleStyles = '' }) {

  const handleClick = () => {
    if (filterAttribute === `sort[${attribute}]=true`) {
      setFilterAttribute(`sort[${attribute}]=true`)
      setFilterDirection(filterDirection === `sort[${attribute}_direction]=ASC` ? `sort[${attribute}_direction]=DESC` : `sort[${attribute}_direction]=ASC`)
    } else {
      setFilterAttribute(`sort[${attribute}]=true`)
      setFilterDirection(`sort[${attribute}_direction]=DESC`)
    }
  }

  const arrowDirection = () => {
    if (filterDirection === `sort[${attribute}_direction]=ASC`) return <FaCaretUp />

    return <FaCaretDown />
  }

  return(
    <th
      className={`text-left px-2 font-header text-grey-300 font-semibold ${width} hover:cursor-pointer`}
      onClick={() => handleClick()}
    >
      <div className={`${titleStyles} flex justify-center`}>
        {title}
        {filterAttribute === `sort[${attribute}]=true` && arrowDirection()}
      </div>
    </th>
    )
}