import useStore from "../../../store"
import { useTranslation } from "react-i18next"
import { Button, Input, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react"
import { DateTime } from "luxon"
import { useState } from "react"
import DatePicker from "react-datepicker"
import Spinner from "../../common/Spinner"
import tailwind from "../../../../tailwind.config"
import { useIsMobile } from "../../../hooks/useIsMobile"


export default function PreviousInventoryDownload() {
  const { t } = useTranslation()
  const fetchPreviousUnits = useStore((state) => state.fetchPreviousUnits)
  const [startDate, setStartDate] =  useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const downloadingPreviousUnits = useStore((state) => state.downloadingPreviousUnits)
  const green = tailwind.theme.colors["green"]
  const grey100 = tailwind.theme.colors["grey-100"]
  const grey900 = tailwind.theme.colors["grey-900"]
  const today = new Date().toLocaleDateString('en-ZA')
  const isMobile = useIsMobile()

  const handleClick = (reportType) => {
    fetchPreviousUnits(startDate.toLocaleDateString('en-ZA'), endDate.toLocaleDateString('en-ZA'), reportType)
  }

  return(
    <div className="flex flex-col items-center w-full w-full lg:w-1/2 text-left bg-grey-900 rounded mt-2">
    <Accordion allowToggle w={'full'} bg={grey900}>
      <AccordionItem>
        <h2>
          <AccordionButton px={2}>
            <div className="flex justify-between w-full">
              <div className="font-header font-semibold text-md text-green">{t('Reports')}</div>
              <AccordionIcon />
            </div>
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} pl={2}>
          <div className="bg-grey-900 mb-2 pb-2 pt-0.5 rounded-b">
            <div className="text-sm text-grey-300 font-medium mb-2">
              {t("Previous Inventory Report")}
            </div>
            <div className="flex space-x-4 mb-4">
              <DatePicker
                id="start-date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="M/d/yy"
                className="px-1 py-0 w-full bg-grey-900 border-2 border-grey-700 focus:border-grey-300 focus:ring-0 rounded"
                showPopperArrow={true}
                maxDate={today}
                popperPlacement="auto"
                withPortal={isMobile}
                data-testid="start-date"
              />
              <DatePicker
                id="end-date"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="M/d/yy"
                className="px-1 py-0 w-full bg-grey-900 border-2 border-grey-700 focus:border-grey-300 focus:ring-0 rounded"
                showPopperArrow={true}
                maxDate={today}
                popperPlacement="auto"
                withPortal={isMobile}
                data-testid="end-date"
              />
            </div>
            {downloadingPreviousUnits ?
              <div className="flex justify-center items-center h-10">
                <Spinner color={green} height={"25px"} />
              </div>
              :
              <div className="h-10 space-x-4">
                <Button
                  variant="outline"
                  color={grey100}
                  border={'2px'}
                  bg="transparent"
                  onClick={() => handleClick('PDF')}
                  size={'sm'}
                  sx={{
                    borderRadius: 'full',
                    borderColor: green,
                  }}
                >
                  Download PDF
                </Button>
                <Button
                  variant="outline"
                  color={grey100}
                  border={'2px'}
                  bg="transparent"
                  onClick={() => handleClick('Excel')}
                  size={'sm'}
                  sx={{
                    borderRadius: 'full',
                    borderColor: green,
                  }}
                >
                  Download Excel
                </Button>
              </div>
            }
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </div>
  )
}