import { Button } from "@chakra-ui/react"
import { useDisclosure } from "@chakra-ui/react"
import ReusableModal from "../../common/ReusableModal"
import tailwind from "../../../../tailwind.config"
import useStore from "../../../store"
import { useState } from "react"

export default function UploadUnitFile() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const green = tailwind.theme.colors["green"]
  const unit = useStore((state) => state.unit)
  const createUnitFile = useStore((state) => state.createUnitFile)
  const [file, setFile] = useState(null)
  const red = tailwind.theme.colors["fpx-red"]
  const grey900 = tailwind.theme.colors["grey-900"]
  const [imagePreview, setImagePreview] = useState(null)
  const user = useStore((state) => state.user)

  const handleSubmit = () => {
    const formData = new FormData()
    formData.append('unit_file[ufile]', file)
    formData.append('unit_file[unit_id]', unit.id)
    formData.append('unit_file[name]', "Bill Of Sale (BOS)")
    formData.append('unit_file[description]', "Bill Of Sale")
    formData.append('unit_file[created_by]', user.full_name)
    formData.append('unit_file[uploaded_from]', "Dealer Portal")

    createUnitFile(formData)
    onClose()
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    setFile(file)

    const reader = new FileReader()
    reader.onloadend = () => {
      setImagePreview(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleRemoveFile = () => {
    setFile(null)
    setImagePreview(false)
  }

  return(
    <div>
      <Button
        variant="outline"
        border={'2px'}
        bg="transparent"
        onClick={onOpen}
        sx={{
          borderRadius: 'full',
          borderColor: green,
          color: 'white'
        }}
      >
        Upload Bill of Sale
      </Button>
      <ReusableModal
        isOpen={isOpen}
        onClose={onClose}
        handleSubmit={handleSubmit}
        file={file}
        headerText={"Upload Unit File"}
        dataTestId={'upload-unit-file'}
        submitText={"Submit"}
      >
      <label className="relative bg-gray-300 rounded-md cursor-pointer">
        <span className="py-2 px-4 inline-block rounded border-2 border-grey-100 mb-4">Choose File</span>
        <input
          type="file"
          className="sr-only"
          onChange={handleFileUpload}
        />
      </label>
      {file && (
        <Button
          size="sm"
          color={grey900}
          bg={red}
          onClick={handleRemoveFile}
          className="ml-2"
        >
          X
        </Button>
      )}
      {file && (
        <div>
          <img src={imagePreview} alt="Preview" className="mt-2 w-24 h-24 object-cover border border-gray-300 rounded" />
        </div>
      )}
      </ReusableModal>
    </div>
  )
}