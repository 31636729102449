import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"
import tailwind from "../../../tailwind.config"

export default function ReusableModal({isOpen, onClose, headerText, submitText, children, handleSubmit= null, file, dataTestId}) {
  const green = tailwind.theme.colors["green"]
  const red = tailwind.theme.colors["fpx-red"]
  const grey900 = tailwind.theme.colors["grey-900"]
  const deepOcean = tailwind.theme.colors["deep-ocean"]

  return(
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={green} fontSize={'md'} fontWeight={'semibold'} data-testid={dataTestId}>{headerText}</ModalHeader>
        <ModalCloseButton aria-label="Close modal"/>
        <ModalBody bg={deepOcean}>
          {children}
        </ModalBody>
        <ModalFooter>
          {handleSubmit &&
            <Button
              bg={green}
              sx={{
                borderRadius: 'full'
              }}
              color={grey900}
              onClick={handleSubmit}
              className="mt-2"
              isDisabled={!file}
              data-testid={`${dataTestId}-button`}
            >
              {submitText}
            </Button>
          }
          <Button
            bg={red}
            sx={{
              borderRadius: 'full'
            }}
            color={grey900}
            onClick={onClose}
            className="mt-2 ml-2"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}