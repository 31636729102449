import { useTranslation } from "react-i18next"
import useStore from "../../../store"
import DatePicker from "react-datepicker"
import { useState } from "react"
import { markUnitSoldEnabled } from "../../../utilities/featureFlags"
import tailwind from "../../../../tailwind.config"
import { Button } from "@chakra-ui/react"
import { DateTime } from "luxon"
import Spinner from "../../common/Spinner"
import UploadUnitFile from "./UploadUnitFile"
import InfoItem from "../../common/InfoItem"
import ReusableModal from "../../common/ReusableModal"
import { useDisclosure } from "@chakra-ui/react"
import { HappyButton } from "../../common/Buttons"

export default function MarkSold() {
  const { t } = useTranslation()
  const [soldOnDate, setSoldOnDate] = useState(new Date())
  const markUnitSold = useStore((state) => state.markUnitSold)
  const unit = useStore((state) => state.unit)
  const markingUnitSold = useStore((state) => state.markingUnitSold)
  const green = tailwind.theme.colors["green"]
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleDateChange = (date) => {
    setSoldOnDate(date.toLocaleDateString('en-ZA'))
  }

  const handleSubmit = () => {
    markUnitSold(unit, soldOnDate)
    onClose()
  }

  return(
      <div>
        <div className="m-2">
          {markUnitSoldEnabled && !unit.attributes.sold &&
            <div>
              {markingUnitSold ?
                <Spinner color={green}/>
                :
                <HappyButton text={"Mark Unit Sold"} onClick={onOpen}/>
              }
            </div>
          }
        </div>
        <ReusableModal
          isOpen={isOpen}
          onClose={onClose}
          handleSubmit={handleSubmit}
          headerText={"Mark Unit Sold"}
          dataTestId={'mark-unit-sold'}
          submitText={"Submit"}
          file={soldOnDate}
        >
          <label className="text-sm text-grey-300 mb-1">{t('Date Sold')}</label>
          <DatePicker
            selected={soldOnDate}
            onChange={(date) => handleDateChange(date)}
            className="px-1 w-full bg-grey-900 border-2 border-grey-700 focus:border-grey-300 focus:ring-0 rounded"
          />
        </ReusableModal>
      </div>
  )
}