import useStore from '../store'
import fxLogo from '../../public/fx-header-logo-white.png'
import ohNoCat from '../../public/oh-no-cat.jpeg'
import { useNavigate } from 'react-router-dom'
import { NeutralButton } from './common/Buttons'
import NavBar from './NavBar/NavBar'
import MessageHandling from './MessageHandling/MessageHandling'
import { useTranslation } from 'react-i18next'

export default function ErrorDisplay({ error, resetError}) {
  const { t } = useTranslation()
  const user = useStore((state) => state.user)
  const navigate = useNavigate()
  const logoutUser = useStore((state) => state.logoutUser)

  const clearCache = () => {
    resetError()
    localStorage.clear()
    sessionStorage.clear()
    logoutUser()
    navigate('/')
  }

  return (
    <div className="flex flex-col grow overflow-y-auto h-screen items-center justify-evenly">
      <img src={fxLogo} alt="logo" className="h-20" />
      <div className="flex flex-col justify-center items-center text-center mx-4">
        <div className="text-xl text-center font-bold w-1/2 my-3">
          {t('Oops. Something went wrong.')}
        </div>
        <img src={ohNoCat} alt="oh no!" className="rounded-full h-56" />
        <div className='my-3'>
          {t('Not to fear, maybe one of these options will get you back on track.')}
        </div>
      </div>
      <div>
        <NeutralButton
          text={user ? t("To Dashboard") : t("Login")}
          onClick={() => {
            resetError()
            navigate('/')
          }}
          style="my-4"
        />
        <NeutralButton
          text={t("Go Back")}
          onClick={() => {
            resetError()
            window.history.back()
          }}
          style="my-4"
        />
        <NeutralButton
          text={t("Clear Cache")}
          onClick={clearCache}
          style="my-4"
        />
      </div>
    </div>
  )
}